<template>
  <b-container>
    <b-row>
      <b-col offset-lg="2" lg="8">
        <b-card>

          <b-row v-if="intialLoading">
            <b-col cols="12">
              <div class="d-flex justify-content-center mb-5">
                <b-link :to="{ name: 'dashboard' }" class="brand-logo">
                  <img :src="appLogoImage" />
                </b-link>
              </div>
            </b-col>

            <b-col cols="12">
              <div
                class="d-flex align-items-center justify-content-center"
                style="height: 20rem"
              >
                <b-spinner
                  style="width: 5rem; height: 5rem; font-size: 2rem"
                  variant="primary"
                />
              </div>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col cols="12">
              <div class="d-flex justify-content-center  mb-5">
                <b-link :to="{ name: 'dashboard' }" class="brand-logo">
                  <img :src="appLogoImage" />
                </b-link>
              </div>
            </b-col>

            <b-col md="3">
              <div>
                <b-form-group label="Invoice Number:">
                  <strong>{{ invoice.invoice_number }}</strong>
                </b-form-group>
              </div>
            </b-col>

            <b-col md="3">
              <b-form-group label="Amount Paid:">
                <strong v-if="invoice.invoice_transactions_paid <= 0">
                  -
                </strong>
                <strong v-else>
                  KSH {{ invoice.invoice_transactions_paid }}
                </strong>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Amount Due:">
                <strong
                  v-if="invoice.invoice_transactions_paid >= invoice.amount"
                >
                  -
                </strong>
                <strong v-else>
                  KSH {{ invoice.amount - invoice.invoice_transactions_paid }}
                </strong>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Total Amount:">
                <strong>{{ invoice.amount }}</strong>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Invoice Status:">
                <strong
                  v-if="invoice.status == 'partial'"
                  class="text-capitalize text-warning"
                >
                  Partially Paid
                </strong>

                <strong
                  v-else-if="invoice.status == 'paid'"
                  variant=""
                  class="text-capitalize text-success"
                >
                  Paid
                </strong>

                <strong
                  v-else-if="invoice.status == 'unpaid'"
                  class="text-capitalize text-danger"
                >
                  Unpaid
                </strong>

                <strong v-else class="text-capitalize text-primary">
                  {{ invoice.status }}
                </strong>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Invoice Type:">
                <strong>{{ invoice.mode }}</strong>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Invoice Description:">
                <strong>{{ invoice.message }}</strong>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Due Date:">
                <strong>{{ invoice.format_created_at }}</strong>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <div class="table-responsive">
                <table class="table table-striped table-borderless">
                  <thead>
                    <tr>
                      <th class="bg-primary text-white">Description</th>
                      <th class="bg-primary text-white">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-if="invoice.message">
                        {{ invoice.message }}
                      </td>
                      <td v-else>
                        Service Utilization for Postpaid Account
                      </td>
                      <td>{{invoice.amount}}</td>
                    </tr>
                    <tr v-for="completedTransaction in completedTransactions" :key="completedTransaction.ID">
                      <td v-if="invoice.message">
                        <span v-if="completedTransaction.mode == 'Bank Transaction'">
                          {{ completedTransaction.mode }}
                        </span>
                        <span v-else>
                          {{ completedTransaction.mode }} Transaction
                        </span>
                      </td>
                      <td>-{{completedTransaction.amount}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-end pr-2 mt-2">
                <table>
                  <tbody style="line-height: 2.5">
                    
                    <tr style="font-size:1.2rem; border-bottom: 1px solid #6e6b7b;">
                      <td>Total Amount Paid: </td>
                      <th class="pl-1"
                        v-if="invoice.invoice_transactions_paid <= 0"
                      >
                        0
                      </th>
                      <th class="pl-1" v-else>
                        KSH {{ invoice.invoice_transactions_paid }}
                      </th>
                    </tr>
                    <tr style="font-size:1.2rem;">
                      <td>Total Amount Due: </td>
                      <th class="pl-1"
                        v-if="invoice.invoice_transactions_paid >= invoice.amount"
                      >
                        0
                      </th>
                      <th class="pl-1" v-else>
                        KSH {{ invoice.amount - invoice.invoice_transactions_paid }}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>

            <b-col cols="12" class="mt-2">
              <h4>Transaction History</h4>
              <b-table
                show-empty
                :empty-text="
                  'No transactions found for invoice # ' +
                  invoice.invoice_number
                "
                :empty-filtered-text="
                  'No transactions found for invoice # ' +
                  invoice.invoice_number
                "
                hover
                striped
                responsive
                :items="transactions"
                :fields="transactionsFields"
                ref="invoicesTable"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(amount)="data">
                  <span class="text-capitalize"
                    >KSH {{ data.item.amount }}</span
                  >
                </template>

                <template #cell(status)="data">
                  <span class="text-capitalize">{{ data.item.status }}</span>
                </template>
              </b-table>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BSpinner,
  BFormGroup,
  BLink,
  BImg,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BSpinner,
    BFormGroup,
    BLink,
    BImg,
  },
  props: ["id"],
  computed: {
    completedTransactions(){
      return this.transactions.filter(transaction => transaction.status == 'completed');
    }
  },
  data() {
    const { appLogoImage } = $themeConfig.app;
    const { appUrl } = $themeConfig.app;
    return {
      intialLoading: false,
      invoice: {},
      transactionsFields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "amount",
          label: "AMOUNT",
        },
        {
          key: "status",
          label: "STATUS",
        },
        {
          key: "mode",
          label: "PAYMENT MODE",
        },
        {
          key: "format_created_at",
          label: "PAYMENT DATE TIME",
        },
      ],
      transactions: [],
      appLogoImage,
      appUrl,
    };
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("view-user");
    },
  },
  created() {
    if (this.id && this.id != null && this.id != undefined) {
      this.intialLoading = true;
      this.$http.get("/outside/invoice/" + this.id + "/show").then((response) => {
        let data = response.data.data;
        this.invoice = data.invoice;
        this.transactions = data.transactions;
        this.intialLoading = false;
      });
    } else {
        this.intialLoading = false;
    }
  },
};
</script>